<template>
  <div class="dataCallout card static">
    <div class="card-content has-text-centered is-width-100">
      <p class="title is-5">
        {{ title }}
      </p>

      <p><b class="is-size-1">{{ used }}</b> <span class="is-size-5">/ {{ limit }}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataCallout',
  props: {
    used: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
