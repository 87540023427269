<template>
  <section
    v-if="isLoading"
  >
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
    />
  </section>
  <section
    v-else
  >
    <section class="margin-top-4-rem">
      <PlanUsage />
    </section>

    <section
      v-if="shouldShowInvoiceSection()"
      class="margin-top-2-rem"
    >
      <InvoiceSection />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PlanUsage from '../components/PlanUsage';
import InvoiceSection from '../components/InvoiceSection';

export default {
  name: 'PageBilling',
  components: {
    PlanUsage,
    InvoiceSection,
  },
  data() {
    return {
      isLoading: true
    }
  },
  created() {
    this.loadChargeBeeScript();
    this.isLoading = true;
    this.getBillingInfo(true).then(() => {
      this.plan = this.getPlan();
      this.isLoading = false;
    });
  },
  methods: {
    shouldShowInvoiceSection() {
      return !this.planIsOnTrial();
    },
    loadChargeBeeScript() {
      const chargeBeeScript = document.createElement('script');
      chargeBeeScript.setAttribute('src', '//js.chargebee.com/v2/chargebee.js');
      document.head.appendChild(chargeBeeScript);
    },
    ...mapGetters('billing', ['planIsOnTrial', 'getPlan']),
    ...mapActions('billing', ['getBillingInfo']),
  }
};
</script>

<style scoped>

</style>
