import ConfigHelper from './configHelper';

export async function getChargebeeInstance() {
  try {
    return window.Chargebee.getInstance();
  } catch (e) {
    const site = await ConfigHelper.getConfigValue('CHARGEBEE_SITE')
    return window.Chargebee.init({
      site,
    });
  }
}
