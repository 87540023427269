<template>
  <section style="margin-top: 4rem;">
    <div class="level border-bottom padding-bottom-10">
      <div class="level-item level-left">
        <h1 class="title is-size-5">
          Invoicing
        </h1>
      </div>
      <div class="level-item level-right buttons">
        <b-button
          v-if="shouldShowButton()"
          class="inverted-button"
          @click="updatePaymentMethod()"
        >
          Update Payment Method
        </b-button>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-quarter">
        <PendingPayment
          type="latest"
        />

        <PendingPayment
          type="next"
        />
      </div>
      <div class="column">
        <InvoiceList />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PendingPayment from './PendingPayment';
import InvoiceList from './InvoiceList';
import { getChargebeeInstance } from '../services/chargebeeHelper';
import BillingAPI from '../api/modules/Billing';

export default {
  name: 'InvoiceSection',
  components: {
    PendingPayment,
    InvoiceList,
  },
  data() {
    return {
      plan: {},
      chargebeeInstance: null,
    };
  },
  created() {
    this.getBillingInfo(true).then(() => {
      this.plan = this.getPlan();
    });
  },
  methods: {
    shouldShowButton() {
      if (Object.entries(this.plan).length === 0) {
        return false;
      }

      return this.plan.card !== false;
    },
    async updatePaymentMethod() {
      if (this.chargebeeInstance === null) {
        this.chargebeeInstance = await getChargebeeInstance();
      }

      const hostedPage = BillingAPI.updateCard().then((res) => res.data);
      const self = this;

      this.chargebeeInstance.openCheckout({
        hostedPage() {
          return new Promise(resolve => resolve(hostedPage))
        },
        error() {
          self.$buefy.toast.open({
            message: 'There was an error changing your payment method, sorry',
            type: 'is-danger',
          });
        },
        success() {
          self.$buefy.toast.open({
            message: 'Payment method updated',
            type: 'is-success',
          });
        },
      });
    },
    ...mapGetters('billing', ['getPlan']),
    ...mapActions('billing', ['getBillingInfo']),
  },
};
</script>

<style scoped>

</style>
