<template>
  <section class="churn-feedback">
    <div class="modal-card">
      <section class="container">
        <div>
          <div class="cancelled-title">
            We've cancelled your subscription
          </div>
          <div>
            We're always improving our product and your feedback matters!
          </div>
          <br>
          <div>
            Why did you cancel your subscription with us?
          </div>
          <section class="feedback-area">
            <b-field
              v-for="(reason, index) in predefinedReasons"
              :key="index"
            >
              <b-checkbox
                v-model="input.reasons"
                :native-value="reason"
              >
                <label>{{ reason }}</label>
              </b-checkbox>
            </b-field>
            <div class="text-area-input">
              <label>Other</label>
              <b-input
                v-model="input.otherFeedback"
                :maxlength="maxLength.otherFeedback"
                rows="2"
                type="textarea"
                placeholder="please specify..."
              />
            </div>
            <div>
              Thank You!
              <br>
              We liked having you as a loyal customer and hope
              <br>
              you'll be back soon.
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button
              class="rounded-button"
              label="DONE"
              type="is-primary"
              @click="submitFeedback"
            />
          </footer>
        </div>
      </section>
      <div
        class="close-modal"
        @click="close"
      />
    </div>
  </section>
</template>

<script>
import { NotificationProgrammatic as Notification } from 'buefy';
import BillingAPI from '../api/modules/Billing';

export default {
  name: 'ChurnFeedbackModal',
  data() {
    return {
      input: {
        reasons: [],
        otherFeedback: '',
      },
      predefinedReasons: [],
      maxLength: {
        otherFeedback: 200
      }
    };
  },
  computed: {
    isFeedbackEmpty() {
      return this.input.reasons.length === 0
        && this.input.otherFeedback.length === 0
    },
  },
  async created() {
    const response = await BillingAPI.getReasonsForChurn();
    this.predefinedReasons = (response.data && response.data.reasons) || [];
  },
  methods: {
    close(isSuccess = true) {
      if (isSuccess) {
        this.showNotification();
      }
      this.$emit('close');
    },
    showNotification() {
      Notification.open({
        duration: 6000, // 6 seconds
        message: 'Cancelling your subscription takes sometime. Please refresh this page in few minutes.',
        type: 'is-success',
      });
    },
    isFeedbackValid() {
      let message;
      if(this.isFeedbackEmpty) {
        message = 'Please select at least one field';
      } else if (this.input.otherFeedback.length > this.maxLength.otherFeedback) {
        message = 'Please do not exceed the character limit of the feedback input';
      }
      if (message) {
        this.$buefy.toast.open({
          message,
          type: 'is-warning',
          duration: 4000
        });
        return false;
      }
      return true;
    },
    submitFeedback() {
      if (!this.isFeedbackValid()) return;
      if (!this.isFeedbackEmpty) {
        const errorToastOptions = {
          message: 'There is an error while submitting feedback. Please contact support.',
          type: 'is-danger',
        };
        BillingAPI.churnFeedback(this.input).then((res) => {
          if (res.data.status === 'Success') {
            this.$buefy.toast.open({
              message: 'Feedback Submitted',
              type: 'is-success',
            });
            this.close(true);
          } else {
            this.$buefy.toast.open(errorToastOptions);
            this.close(false);
          }
        }).catch(() => {
          this.$buefy.toast.open(errorToastOptions);
          this.close(false);
        });
      }
    },
  },
};
</script>
<style scoped>
.container {
  padding: 20px 25px 0 30px;
  width: 480px;
}
.modal-card {
  width: 495px;
  border-radius: 20px !important;
  color: #282C2E;
}
.field {
  margin-bottom: 10px;
}
.feedback-area {
  width: 340px;
  padding-top: 10px;
  font-size: 0.9rem;
}
.cancelled-title {
  font-size: 1.4rem;
  margin-bottom: 6px;
  color: #EF0B25;
}
.modal-card-foot {
  padding-right: 0;
}
.text-area-input {
  width: 280px;
}
</style>
