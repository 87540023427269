<template>
  <section class="is-height-100">
    <div class="margin-bottom-1-rem">
      <h2 class="billing-title font-22 level-left">
        Select Data Sources
      </h2>
    </div>
    <div class="column margin-bottom-1-rem">
      <b-field>
        <b-input
          v-model="connectorSearch"
          placeholder=" Search for Data Source"
          class="search-input connector-search"
          type="search"
          icon-pack="fas"
          icon="search"
        />
      </b-field>
    </div>
    <ul class="scroll-y is-height-75">
      <li
        v-for="addOn in filteredAddons"
        :key="addOn.addonId"
        :class="getClassName(addOn)"
      >
        <b-checkbox
          v-model="plan.connectors"
          :native-value="addOn.addonId"
          :disabled="checkboxIsDisabled(addOn)"
          class="is-width-100"
        >
          <div class="columns">
            <div class="column is-1">
              <img
                :src="getServiceLogo(addOn.identifier)"
                class="connector-icon margin-left-half-rem margin-right-half-rem level-center"
              >
            </div>
            <div class="column is-two-thirds padding-top-15-px font-dark font-18">
              {{ getConnectorDisplayName(addOn) }}
              <span
                v-if="isBetaConnector(addOn)"
              >
                ( Free )
              </span>
            </div>
            <div
              v-if="connectorStatus(addOn)"
              class="column is-one-fifth font-light has-text-centered padding-top-12-px"
            >
              <b-tooltip
                type="is-light"
                position="is-left"
                size="is-large"
                multilined
                :label="displayLabelForConnector(addOn)"
                class="is-width-120"
              >
                <div v-if="isFreemiumConnector(addOn)">
                  <span class="font-18 font-dark"> Free </span>
                  <span class="icon has-text-warning-dark">
                    <i class="fas fa-info-circle margin-left-0.5-rem" />
                  </span>
                </div>
                <b-button
                  v-else
                  rounded
                  class="is-width-100 no-click no-transform connector-status font-12"
                >
                  {{ connectorStatus(addOn) }}
                </b-button>
              </b-tooltip>
            </div>
          </div>
        </b-checkbox>
      </li>
    </ul>
  </section>
</template>

<script>

import { BETA_CONNECTORS, STATUSES, GA_EVENTS, GA_CATEGORIES } from '../../../../config/Constants';

export default {
  name: 'ConnectorSelect',
  props: {
    selected: {
      type: Array,
      required: true,
    },
    availableAddons: {
      type: Array,
      required: true,
    },
    connectors: {
      type: Array,
      required: true
    },
    paidConnectors: {
      type: Array,
      required: true
    },
    planStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      connectorSearch: "",
      plan: {
        connectors: [],
      },
      addonInfo: {}
    };
  },
  computed: {
    filteredAddons() {
      const addons = [...this.availableAddons]
      const sortedAddons = addons.sort((a, b) => a.sortOrder - b.sortOrder);
      if(this.connectorSearch) {
        return sortedAddons.filter((record) => record.name.toLocaleLowerCase().includes(this.connectorSearch.toLocaleLowerCase()));
      }
      return sortedAddons;
    }
  },
  watch: {
    'plan.connectors': function(newSelectedConnectors, oldSelectedConnectors) {
      if (JSON.stringify(newSelectedConnectors) === JSON.stringify(oldSelectedConnectors)) {
        return;
      }
      this.fireGAEvent(newSelectedConnectors, oldSelectedConnectors)

      this.$emit('connectors-changed', this.plan.connectors);
    },
  },
  created() {
    this.plan.connectors = [ ...this.selected ].filter(x => !BETA_CONNECTORS.includes(x));
    this.connectors.forEach((connector) => {
      this.addonInfo[connector.identifier] = connector
    });
  },
  methods: {
    isBetaConnector(addOn) {
      if(addOn && BETA_CONNECTORS.includes(addOn.identifier)) {
        return true;
      }
      return false
    },
    isFreemiumConnector(addOn) {
      return addOn && addOn.isFreemium;
    },
    getClassName(addOn) {
      if(this.selected.includes(addOn.identifier)) {
        if(this.paidConnectors.includes(addOn.identifier)) {
          return 'background-primary-20 list-item'
        }
        return 'background-primary-40 list-item'
      }
      return 'list-item'
    },
    getConnectorDisplayName(addOn) {
      return this.getConnectorByField('internal', addOn.identifier, true)?.name || addOn.name;
    },
    checkboxIsDisabled(addOn) {
      const isBetaConnector = this.isBetaConnector(addOn);
      const isFreemiumConnector = this.isFreemiumConnector(addOn);
      return isBetaConnector || isFreemiumConnector;
    },
    addOnSelected(addOn) {
      return this.selected.includes(addOn.identifier)
    },
    displayLabelForConnector(addOn) {
      let label = '';
      if(this.isBetaConnector(addOn)) {
        label = 'This is a free beta connector';
      } else if(this.isFreemiumConnector(addOn)) {
        label = 'This is a free connector. You can use this without adding to your Cart (No credit card is required)';
      }
      return label;
    },
    connectorStatus(addOn) {
      if(this.isBetaConnector(addOn)) {
        return "Beta"
      }
      if(this.isFreemiumConnector(addOn)) {
        return "Free Connector";
      }
      if(this.addonInfo[addOn.identifier] && this.addonInfo[addOn.identifier].status) {
        switch(this.addonInfo[addOn.identifier].status) {
          case 'trial_expired':
            return 'Trial Expired';
            case 'was_paid':
              return 'Buy Again';
            default:
              return 'In Trial';
        }
      }
      if(this.addonInfo[addOn.identifier]) {
        switch (this.planStatus) {
          case STATUSES.STATUS_ON_TRIAL:
            return "In Trial";
          case STATUSES.STATUS_CANCELLED:
            return "Trial Expired";
          default:
            return ""
        }
      }
      return "";
    },
    fireGAEvent(newSelectedConnectors, oldSelectedConnectors) {
      // skip event fire when the component is mounted
      if(newSelectedConnectors.length - oldSelectedConnectors.length > 1) {
        return
      }
      let selectedConnectors;
      if(newSelectedConnectors.length > oldSelectedConnectors.length) {
        selectedConnectors = newSelectedConnectors.filter(connector => !oldSelectedConnectors.includes(connector));
        this.$gtm.trackEvent({
          category: GA_CATEGORIES.BILLING,
          action: GA_CATEGORIES.CLICK,
          label: GA_EVENTS.CONNECTOR_SELECTED,
          event: GA_EVENTS.CONNECTOR_SELECTED,
          value: selectedConnectors[0]
        })
      } else {
        selectedConnectors = oldSelectedConnectors.filter(connector => !newSelectedConnectors.includes(connector));
        this.$gtm.trackEvent({
          category: GA_CATEGORIES.BILLING,
          action: GA_CATEGORIES.CLICK,
          label: GA_EVENTS.CONNECTOR_DESELECTED,
          event: GA_EVENTS.CONNECTOR_DESELECTED,
          value: selectedConnectors[0]
        })
      }
    }
  },
};
</script>

<style>
  .is-disabled .control-label {
    opacity: 1 !important;
    color: #000 !important;
    pointer-events: all !important;
  }

  .b-checkbox.checkbox[disabled] {
    opacity: 1 !important;
    pointer-events: all !important;
  }

  .b-checkbox.checkbox[disabled] span.check {
    opacity: 0.5 !important;
  }
</style>
