<template>
  <section class="level">
    <h2 class="billing-title font-20">
      Cost Per {{ annualPricing ? 'Year' : 'Month' }}
    </h2>
    <div class="align-right is-size-6">
      <div
        v-if="annualPricing"
        class="font-light font-16"
      >
        <div
          class="margin-right-1-rem is-strikethrough"
        >
          {{ computedEstimate.originalMonthlyPrice | currency }}
        </div>
        <div class="margin-right-neg-8">
          <span
            class="font-18 font-dark font-thick"
          >
            {{ computedEstimate.monthlyPrice | currency }}
          </span>
          <span>
            /mo
          </span>
        </div>
        <div class="margin-right-neg-5">
          <span class="margin-right-1-rem">Billed at</span>
          <span class="margin-right-1-rem is-strikethrough">{{ computedEstimate.originalAnnualPrice | currency }}</span>
          <span
            class="font-16 font-dark font-thick"
          >
            {{ computedEstimate.annualPrice | currency }}
          </span>
          <span>/yr</span>
        </div>
      </div>
      <div
        v-else
        class="font-light font-16 margin-right-neg-23"
      >
        <div
          v-if="computedEstimate.originalMonthlyPrice!==computedEstimate.monthlyPrice"
          class="margin-right-1-rem is-strikethrough"
        >
          {{ computedEstimate.originalMonthlyPrice | currency }}
        </div>
        <span
          class="font-dark font-thick font-18"
        >
          {{ computedEstimate.monthlyPrice | currency }}
        </span>
        /mo
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    name: 'EstimateDisplay',
    components: {},
    props: {
      annualPricing: {
        type: Boolean,
        required: true,
      },
      billingAmount: {
        type: Number,
        required: true,
      },
      annualDiscount: {
        type: Number,
        required: true,
      },
      monthlyDiscount: {
        type: Number,
        required: true,
      },
    },
    computed: {
      computedEstimate() {
        const estimate = {}
        if(this.annualPricing) {
          estimate.originalAnnualPrice = this.billingAmount.toFixed(2)
          estimate.originalMonthlyPrice = ((this.billingAmount) / 12).toFixed(2)
          estimate.annualPrice = ((this.billingAmount) * ((100 - this.annualDiscount) / 100)).toFixed(2)
          estimate.monthlyPrice = (estimate.annualPrice / 12).toFixed(2)
        } else {
          estimate.originalMonthlyPrice = this.billingAmount.toFixed(2);
          estimate.monthlyPrice = ((this.billingAmount) * ((100 - this.monthlyDiscount) / 100)).toFixed(2);
        }
        return estimate
      }
    }
  }
</script>
