<template>
  <section class="is-width-100">
    <section
      v-if="paidConnectors.length > 0"
      id="currentPlan"
    >
      <b-collapse
        animation="slide"
      >
        <template #trigger="props">
          <div
            class="level margin-bottom-1-rem"
            role="button"
          >
            <h2 class="billing-title no-margin-bottom font-20">
              Your Current Plan
            </h2>
            <a
              :class="props.open ? 'arrow arrow-down' : 'arrow arrow-up'"
            />
          </div>
        </template>
        <div>
          <section
            id="connectors"
          >
            <ul>
              <li
                v-for="connector in paidConnectors"
                :key="connector"
              >
                <div class="columns font-18">
                  <div class="column is-small" />
                  <div class="column is-7">
                    {{ getConnectorName(connector) }}
                  </div>
                  <div class="column align-right margin-right-1-rem">
                    {{ computeCost(connector, 1) | currency }}
                  </div>
                </div>
              </li>
            </ul>
          </section>
          <section
            id="features"
          >
            <ul class="">
              <li
                key="accounts"
              >
                <div class="columns font-18">
                  <div class="column is-small no-click font-14 padding-top-1-rem">
                    <i class="fa fa-list-ul" />
                  </div>
                  <div class="column is-7">
                    Accounts ({{ paidAccounts }})
                  </div>
                  <div class="column align-right margin-right-1-rem">
                    {{ computeCost('accounts', paidAccounts) | currency }}
                  </div>
                </div>
              </li>
              <li
                key="users"
              >
                <div class="columns font-18">
                  <div class="column is-small no-click font-14 padding-top-1-rem">
                    <i class="fa fa-users" />
                  </div>
                  <div class="column is-7">
                    Users ({{ paidUsers }})
                  </div>
                  <div class="column align-right margin-right-1-rem">
                    {{ computeCost('team-users', paidUsers) | currency }}
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </b-collapse>
    </section>
    <section
      v-if="hasChanges()"
      class="margin-top-2-rem"
    >
      <h2 class="billing-title margin-bottom-1-rem font-20">
        {{ (paidConnectors.length > 0) ? "Additional Items" : "New Plan" }}
      </h2>
      <section>
        <ul>
          <li
            v-if="(additonalItems.newConnectors.length === 0) && (paidConnectors.length === 0)"
          >
            <div class="columns font-18 font-dark">
              <div class="column is-small" />
              <div class="column">
                No Data sources selected
              </div>
            </div>
          </li>
          <li
            v-for="connector in additonalItems.removedConnectors"
            :key="connector"
          >
            <div class="columns font-18 font-light">
              <div
                class="column is-small"
                @click="addConnector(connector)"
              >
                <div class="add-connector">
                  <i class="fas fa-plus" />
                </div>
              </div>
              <div class="column is-7 is-strikethrough">
                {{ getConnectorName(connector) }}
              </div>
              <div class="column align-right margin-right-1-rem">
                - {{ computeCost(connector, 1) | currency }}
              </div>
            </div>
          </li>
          <li
            v-for="connector in additonalItems.newConnectors"
            :key="connector"
          >
            <div class="columns font-18 font-dark">
              <div class="column is-small" />
              <div class="column is-7">
                {{ getConnectorName(connector) }}
              </div>
              <div class="column align-right margin-right-1-rem">
                {{ computeCost(connector, 1) | currency }}
              </div>
            </div>
          </li>
        </ul>
      </section>
      <section
        v-if="(selectedAccounts !== paidAccounts) || (selectedUsers !== paidUsers)"
      >
        <ul>
          <li
            v-if="selectedAccounts !== paidAccounts"
            key="new-accounts"
          >
            <div class="columns font-18 font-dark">
              <div class="column is-small no-click font-14 padding-top-1-rem">
                <i class="fa fa-list-ul" />
              </div>
              <div class="column is-7">
                Accounts ({{ computeDifference(selectedAccounts, paidAccounts) }})
              </div>
              <div class="column align-right margin-right-1-rem">
                {{ computeCost('accounts', selectedAccounts) - computeCost('accounts', paidAccounts) | currency }}
              </div>
            </div>
          </li>
          <li
            v-if="selectedUsers !== paidUsers"
            key="new-users"
          >
            <div class="columns font-18 font-dark">
              <div class="column is-small no-click font-14 padding-top-1-rem">
                <i class="fa fa-users" />
              </div>
              <div class="column is-7">
                Users ({{ computeDifference(selectedUsers, paidUsers) }})
              </div>
              <div class="column align-right margin-right-1-rem">
                {{ computeCost('team-users', selectedUsers) - computeCost('team-users', paidUsers) | currency }}
              </div>
            </div>
          </li>
        </ul>
      </section>
    </section>
    <section
      v-if="bundleDiscount > 0"
    >
      <ul>
        <li>
          <div class="columns font-18 font-dark">
            <div class="column is-small" />
            <div class="column is-7">
              Bundle Plan Discount
            </div>
            <div class="column align-right margin-right-1-rem">
              - {{ bundleDiscount | currency }}
            </div>
          </div>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
  export default {
    name: 'PlanInfo',
    components: {},
    props: {
      availableAddons: {
        type: Array,
        required: true
      },
      paidConnectors: {
        type: Array,
        required: true,
      },
      paidAccounts: {
        type: Number,
        required: true
      },
      paidUsers: {
        type: Number,
        required: true
      },
      selectedConnectors: {
        type: Array,
        required: true
      },
      selectedAccounts: {
        type: Number,
        required: true,
      },
      selectedUsers: {
        type: Number,
        required: true
      },
      isAnnual: {
        type: Boolean,
        required: true
      },
      bundleDiscount: {
        type: Number,
        required: true
      },
      skinPlanOptions: {
        type: Object,
        required:true
      }
    },
    data() {
      return {
      }
    },
    computed: {
      addonInfo() {
        const info = {}
        this.availableAddons.forEach((addon) => {
          info[addon.addonId] = addon;
        });
        return info;
      },
      additonalItems() {
        const newConnectors = this.selectedConnectors.filter(connector => !this.paidConnectors.includes(connector))
        const removedConnectors = this.paidConnectors.filter(connector => !this.selectedConnectors.includes(connector))
        return {newConnectors, removedConnectors}
      }
    },
    methods: {
      getConnectorName(addonId) {
        if (this.addonInfo[addonId]) {
          return this.getConnectorByField('internal', this.addonInfo[addonId].identifier, true)?.name || addonId;
        }
        return addonId;
      },
      computeDifference(a, b) {
        const diff = a - b;
        return diff > 0 ? `+${diff}` : diff
      },
      computeCost(identifier, quantity) {
        let price = 0
        const addonDetails = this.addonInfo[identifier]
        if(addonDetails && addonDetails.kind === "on_off") {
          price = addonDetails.price / 100
        } else if (identifier === "accounts") {
          price = Math.max(0 , (quantity - this.skinPlanOptions.accounts.chargeAt)) * this.skinPlanOptions.accounts.price
        } else if (identifier === "team-users") {
          price = Math.max(0 , (quantity - this.skinPlanOptions.users.chargeAt)) * this.skinPlanOptions.users.price
        }
        if(this.isAnnual) {
          price = (price * 12).toFixed(2)
        }
        return price;
      },
      hasChanges() {
        let changed = false
        const { newConnectors, removedConnectors } = this.additonalItems
        if(newConnectors.length > 0 || removedConnectors.length > 0) {
          changed = true
        } else if ((this.paidUsers !== this.selectedUsers) || (this.paidAccounts !== this.selectedAccounts)) {
          changed = true
        }
        return changed
      },
      addConnector(connector) {
        this.$emit('add-connector', connector);
      }
    }
  }
</script>
