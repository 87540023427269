<template>
  <section>
    <section class="level-left margin-bottom-1-rem">
      <div class="width-12-px">
        <i
          :class="labelIcon"
          class="no-click"
        />
      </div>
      <h3
        v-if="label"
        class="margin-left-1-rem font-18"
      >
        {{ label }}
      </h3>
      <b-tooltip
        v-if="labelTooltip"
        :label="labelTooltip"
        class="margin-left-1-rem"
      >
        <i class="fa fa-info-circle" />
      </b-tooltip>
    </section>
    <section
      v-if="helpText"
      class="font-12 margin-bottom-1-rem margin-left-2-rem"
    >
      <div>
        {{ helpText }}
      </div>
    </section>
    <b-field
      :message="errorMessage()"
      :type="errorMessage() ? 'is-danger' : ''"
    >
      <b-numberinput
        v-model="numberAmount"
        controls-rounded
        class="section-grey padding-1-rem"
        :min="minimum"
      />
    </b-field>
  </section>
</template>

<script>
export default {
  name: 'NumberSelect',
  props: {
    amount: {
      type: Number,
      required: true,
    },
    minimum: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    labelIcon: {
      type: String,
      required: true
    },
    labelTooltip: {
      type: String,
      required: false,
      default: ""
    },
    helpText: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      numberAmount: 0
    };
  },
  watch: {
    'numberAmount': function() {
      this.$emit('number-changed', this.label, this.numberAmount);
    },
  },
  created() {
    this.numberAmount = this.amount;
  },
  methods: {
    errorMessage() {
      if(this.numberAmount < this.minimum) {
        return `Minimum Value should be ${this.minimum}`
      }
      if (Math.floor(this.numberAmount) !== this.numberAmount) {
        return "Select a Valid Value"
      }
      return ""
    }
  }
};
</script>
