<template>
  <section
    v-if="isLoading"
  >
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
    />
  </section>
  <section
    v-else
  >
    <div
      :class="getFrontEndSkinBusinessUnit()"
    >
      <header class="margin-top-2-rem level">
        <div
          class="margin-right-2-rem font-24 font-light cancel-checkout"
          @click="closeForm"
        >
          <i class="fas fa-arrow-left" />
        </div>
        <div class="column">
          <a
            :href="getFrontEndSkinURL()"
            :title="getFrontEndSkinName()"
          >
            <img
              class="brand-logo"
              :src="getFrontEndSkinLogo()"
              :alt="getFrontEndSkinName()"
            >
          </a>
        </div>
      </header>
      <section
        v-if="onLegacyPlans()"
        class="has-text-centered is-size-5 font-dark"
      >
        <p>Welcome back! We've <b>changed our billing page</b></p>
        <p>Don't worry! There <b>won't be any changes</b> to your <b>invoicing amount</b> as long as you are in the same plan.</p>
        <!-- eslint-disable-next-line vue/max-attributes-per-line -->
        <p><a :href="supportEmail()">Contact Us</a> if you have any questions about your plan or pricing.</p>
      </section>
      <section class="checkout-container parent">
        <div
          v-if="isBusinessUnitTapClicks() && isFreemiumFacebookAds"
          class="column margin-left-4-rem"
        >
          <b-message
            type="is-info"
            size="is-medium"
          >
            Have you tried our <strong> Free Facebook Ads </strong> Connector, yet? Click <a
              :href="getFacebookAdsConnectorURL"
              target="_blank"
              rel="noopener noreferrer"
            >
              here</a>  to get started
          </b-message>
        </div>
        <div class="columns is-height-100 margin-top-2-rem">
          <div class="column is-half padding-sides-5-percent">
            <section :class="isBusinessUnitTapClicks() ? '' : 'is-height-50'">
              <ConnectorSelect
                :selected="plan.selectedConnectors"
                :available-addons="connectorAddons"
                :connectors="connectors"
                :paid-connectors="plan.paidConnectors"
                :plan-status="planStatus"
                @connectors-changed="connectorsChanged"
              />
            </section>
            <section class="margin-top-4-rem">
              <h2 class="billing-title level-left margin-bottom-2-rem font-22">
                Select the Number of Accounts and Users
              </h2>
              <div class="section-grey level-left padding-1-rem">
                <img
                  :src="getLogo('included')"
                  alt="Included"
                  class="height-40"
                >
                <p
                  v-if="isBusinessUnitTapClicks()"
                  class="margin-left-1-rem font-dark font-16"
                >
                  {{ skinPlanOptions.accounts.chargeAt }} Data Source Accounts are included in the plan
                </p>
                <p
                  v-else
                  class="margin-left-1-rem font-dark font-16"
                >
                  Get {{ skinPlanOptions.accounts.chargeAt }} Accounts and {{ skinPlanOptions.users.chargeAt }} Team Users for all the Data Sources
                </p>
              </div>
              <section class="margin-top-2-rem">
                <div class="columns">
                  <div class="column margin-right-10-percent">
                    <NumberSelect
                      :amount="plan.accounts.limit"
                      label="Accounts"
                      label-icon="fa fa-list-ul"
                      label-tooltip=""
                      :help-text="getHelpText('accounts')"
                      :minimum="skinPlanOptions.accounts.chargeAt"
                      @number-changed="numberChanged"
                    />
                  </div>
                  <div class="column margin-right-5-percent">
                    <NumberSelect
                      :amount="plan.users.limit"
                      label="Users"
                      label-icon="fa fa-users"
                      label-tooltip=""
                      :help-text="getHelpText('users')"
                      :minimum="skinPlanOptions.users.chargeAt"
                      @number-changed="numberChanged"
                    />
                  </div>
                </div>
              </section>
            </section>
            <section class="margin-top-2-rem margin-bottom-1-rem">
              <div class="section-grey padding-top-2-rem">
                <ul class="list-info padding-right-1-rem font-light font-16">
                  <li>An Account varies by <b>Data Source</b>. For Example in <b>Facebook Ads</b> this is an Ad Account but in <b>Google Analytics</b> this is a View</li>
                  <li>You can link any number of accounts but pay only for the Accounts which are used to <b>create Reports in Data Studio</b></li>
                </ul>
                <div class="level-right">
                  <b-button
                    rounded
                    tag="a"
                    class="button inverted-button margin-1-rem"
                    target="_blank"
                    :href="getFrontEndSkinSupportUrl()"
                    @click="contactSupport"
                  >
                    Contact Support
                  </b-button>
                </div>
              </div>
            </section>
          </div>
          <div class="column section-grey height-max-content">
            <div class="level-right margin-right-2-rem font-light font-16">
              Buying for: {{ getTeamName() }}
            </div>
            <div class="padding-sides-20-percent">
              <section class="margin-top-1-rem is-centered columns">
                <img
                  class="logo-data-studio"
                  :src="getLogo('google-data-studio')"
                  alt="GDS LOGO"
                >
                <h2 class="billing-title margin-left-1-rem level font-22">
                  {{ getFrontEndSkinName() }} for Google Data Studio
                </h2>
              </section>
              <section class="margin-top-2-rem">
                <PlanInfo
                  :available-addons="availableAddons"
                  :paid-connectors="plan.paidConnectors"
                  :paid-accounts="plan.paidAccounts"
                  :paid-users="plan.paidUsers"
                  :selected-connectors="plan.selectedConnectors"
                  :selected-accounts="plan.accounts.limit"
                  :selected-users="plan.users.limit"
                  :is-annual="plan.annualPricing"
                  :bundle-discount="bundleDiscount"
                  :skin-plan-options="skinPlanOptions"
                  @add-connector="addConnector"
                />
              </section>
              <section class="margin-top-3-rem">
                <EstimateDisplay
                  :annual-pricing="plan.annualPricing"
                  :billing-amount="billingAmount"
                  :annual-discount="getAnnualDiscount"
                  :monthly-discount="getMonthlyDiscount"
                />
              </section>
              <section
                v-if="!isAnnual"
                class="margin-top-2-rem columns level-right is-size-5"
              >
                <div class="margin-right-2-rem font-20">
                  <b-radio
                    v-model="plan.annualPricing"
                    :native-value="false"
                    :class="plan.annualPricing === false ? 'is-primary-color' : ''"
                  >
                    Monthly
                    <br v-if="getMonthlyDiscount">
                    <b
                      v-if="getMonthlyDiscount"
                      class="is-green"
                    >
                      ( Save {{ getMonthlyDiscount }}% )
                    </b>
                  </b-radio>
                </div>
                <div class="font-20">
                  <b-radio
                    v-model="plan.annualPricing"
                    :native-value="true"
                    :class="plan.annualPricing === true ? 'is-primary-color' : ''"
                  >
                    Yearly
                    <br v-if="getMonthlyDiscount">
                    <b class="is-green">( Save {{ getAnnualDiscount }}% )</b>
                  </b-radio>
                </div>
              </section>
              <section v-if="showLimitedOfferMessage()">
                <b-message
                  type="is-warning"
                  size="is-small"
                >
                  Limited Period Offer! Get 50% OFF on everything
                </b-message>
              </section>
              <section class="margin-top-2-rem">
                <b-tooltip
                  type="is-dark"
                  position="is-top"
                  class="is-width-100"
                  :label="checkoutTooltip"
                >
                  <b-button
                    :disabled="checkoutDisabled"
                    rounded
                    native-type="submit"
                    class="button inverted-button font-24 height-50 padding-1-rem is-width-100 no-transform"
                    :icon-right="checkoutDisabled ? 'fas fa-lock' : ''"
                    @click="customisePlan()"
                  >
                    Proceed to Checkout
                  </b-button>
                </b-tooltip>
              </section>
              <section
                v-if="onLegacyPlans() && bundleDiscount === 0"
                class="has-text-centered margin-top-1-rem"
              >
                <div class="help is-danger">
                  Changing your current plan removes bundle plan discount
                </div>
              </section>
              <section
                v-if="getFrontEndSkinFAQUrl()"
                class="margin-bottom-1-rem margin-top-5-rem has-text-centered font-dark"
              >
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                Have questions? Please check our <a :href="getFrontEndSkinFAQUrl()" target="_blank">Pricing FAQs</a>
              </section>
            </div>
            <div
              v-if="isBusinessUnitTapClicks() && isFreemiumFacebookAds"
              class="level-right margin-right-2-rem font-light"
              style="font-size:smaller;"
            >
              <b-tooltip
                type="is-dark"
                position="is-bottom"
                :label="
                  'We get real-time data from the Facebook API. We’ll support all Freemium users as long as the vendor supports our quota limit'"
                size="is-large"
                multilined
              >
                Terms & Conditions
              </b-tooltip>
            </div>
            <div v-if="isApplyDiscount()" class="level-right margin-right-2-rem font-light" style="font-size:smaller;">
              <b-tooltip
                type="is-dark"
                position="is-bottom"
                :label="
                  'This 50% discount offer is valid till 30, November 2022 only. From the date of purchase, this offer will be valid for the next 6 months for monthly subscriptions and 1 year for annual subscriptions. Offer is applicable on the invoice amount. For details, contact support'"
                size="is-large"
                multilined
              >
                Terms & Conditions
              </b-tooltip>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { NotificationProgrammatic as Notification } from 'buefy'
  import { getChargebeeInstance } from '../../services/chargebeeHelper';
  import BillingAPI from '../../api/modules/Billing';
  import NumberSelect from './elements/NumberSelect';
  import ConnectorSelect from './elements/ConnectorSelect';
  import EstimateDisplay from './elements/EstimateDisplay';
  import PlanInfo from './elements/PlanInfo';
  import { CONNECTOR_CONFIG } from '../../../config/ConnectorConfig';
  import {
    PLANS,
    STATUSES,
    BETA_CONNECTORS,
    ACTIVE_PLANS,
    BUNDLE_PLAN_DISCOUNTS,
    GA_EVENTS,
    GA_CATEGORIES,
    DATA_STUDIO_URL,
    FACEBOOK_ADS_IDENTIFIER,
    ACTIVE_ANNUAL_PLANS, ANNUAL_PLAN_FOR_BASIC_PLAN, DISCOUNT_FOR_ANNUAL_PLAN, LIMIT_ADDONS_PREFIX_FOR_PLAN
  } from '../../../config/Constants';

  export default {
    name: 'CustomisePlanForm',
    components: {
      ConnectorSelect,
      NumberSelect,
      EstimateDisplay,
      PlanInfo
    },
    props: {
      users: {
        type: Object,
        required: true,
      },
      accounts: {
        type: Object,
        required: true,
      },
      connectors: {
        type: Array,
        required: true,
      },
      planId: {
        type: String,
        required: false,
        default: 'basic-plan',
      },
      isAnnual: {
        type: Boolean,
        required: true,
        default: false,
      },
      planStatus: {
        type: String,
        required: true
      },
      applicableDiscounts: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        isLoading: true,
        skinPlanOptions: {},
        plan: {
          users: {
            limit: 1,
          },
          accounts: {
            limit: 1,
          },
          selectedConnectors: [],
          annualPricing: false,
          planId: PLANS.PLAN_BASIC,
          paidConnectors: [],
          paidUsers: 0,
          paidAccounts: 0
        },
        availableAddons: [], // all addons
        connectorAddons: [], // only connector addons
        billingAmount: 0,
        checkoutDisabled: true,
        checkoutTooltip: "",
        hasLoaded: false,
        chargeBeeSteps: []
      };
    },
    computed: {
      ...mapState('user', ['data']),
      getAnnualDiscount() {
        if (this.applicableDiscounts && this.applicableDiscounts.annual) {
          return this.applicableDiscounts.annual;
        }
        if (ACTIVE_ANNUAL_PLANS.includes(this.planId)) {
          return DISCOUNT_FOR_ANNUAL_PLAN[this.planId] || 20;
        }
        return DISCOUNT_FOR_ANNUAL_PLAN[ANNUAL_PLAN_FOR_BASIC_PLAN[this.planId]] || 20;
      },
      getMonthlyDiscount() {
        return (this.applicableDiscounts && this.applicableDiscounts.monthly) || 0;
      },
      getFacebookAdsConnectorURL() {
        // currently we always display production connector deployment url of looker studio
        const config = CONNECTOR_CONFIG[FACEBOOK_ADS_IDENTIFIER];
        const businessUnit = this.getFrontEndSkinBusinessUnit();
        return `${DATA_STUDIO_URL}${config[businessUnit]}`
      },
      isFreemiumFacebookAds() {
        const facebookAddon = this.availableAddons.find(addon => addon.identifier === FACEBOOK_ADS_IDENTIFIER);
        return facebookAddon?.isFreemium;
      },
      bundleDiscount() {
        if(!this.onLegacyPlans()) {
          return 0
        }
        if(this.hasChanges()) {
          return 0
        }
        return BUNDLE_PLAN_DISCOUNTS[this.planId] || 0
      },
      addonInfo() {
        const info = {}
        this.availableAddons.forEach((addon) => {
          info[addon.addonId] = addon;
        })
        return info;
      }
    },
    watch: {
      'plan.annualPricing': function() {
        if(this.plan.annualPricing) {
          if(this.hasLoaded) {
            this.$gtm.trackEvent({
              category: GA_CATEGORIES.BILLING,
              action: GA_CATEGORIES.CLICK,
              label: GA_EVENTS.BILLING_PERIOD,
              event: GA_EVENTS.BILLING_PERIOD,
              value: "annual"
            })
          }
          if (!ACTIVE_ANNUAL_PLANS.includes(this.planId)) {
            this.plan.planId  = ANNUAL_PLAN_FOR_BASIC_PLAN[this.planId] || PLANS.PLAN_ANNUAL;
          }
        } else {
          if(this.hasLoaded) {
            this.$gtm.trackEvent({
              category: GA_CATEGORIES.BILLING,
              action: GA_CATEGORIES.CLICK,
              label: GA_EVENTS.BILLING_PERIOD,
              event: GA_EVENTS.BILLING_PERIOD,
              value: "monthly"
            })
          }
          this.plan.planId = ACTIVE_PLANS.includes(this.planId) ? this.planId : PLANS.PLAN_BASIC
        }
        this.debounceEstimate();
      },
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeUnloadEventHandler);
    },
    created() {
      this.chargeBeeSteps = [];
      window.addEventListener('beforeunload', this.beforeUnloadEventHandler);
      this.skinPlanOptions = this.getFrontEndSkinPlanOptions();
      this.plan.users.limit = this.skinPlanOptions.users.default;
      this.plan.accounts.limit = this.skinPlanOptions.accounts.default;
      this.pullAddons(false).then(() => {
        const availableAddons = this.getAddons();
        for (const addOn of availableAddons) {
          this.availableAddons.push(addOn);
          if (addOn.identifier === 'team-users') {
            this.skinPlanOptions.users = {
             min: 1,
             max: this.skinPlanOptions.users.max,
             default: addOn.tiers[0].ending_unit,
             chargeAt: addOn.tiers[0].ending_unit,
              price: addOn.tiers[1].price / 100,
            };
          } else if (addOn.identifier === 'accounts') {
            this.skinPlanOptions.accounts = {
              min: 1,
              max: this.skinPlanOptions.accounts.max,
              default: addOn.tiers[0].ending_unit,
              chargeAt: addOn.tiers[0].ending_unit,
              price: addOn.tiers[1].price / 100
            };
          }
          if (addOn.identifier !== 'team-users' && addOn.identifier !== 'accounts') {
            this.connectorAddons.push(addOn);
          }
        }
        this.debounceEstimate()
        this.isLoading = false
      });
      // make copies so it doesn't update the parent until we're done
      if(![STATUSES.STATUS_ON_TRIAL, STATUSES.STATUS_CANCELLED].includes(this.planStatus)) {
        this.plan.users = {
          ...this.users
        };
        this.plan.accounts = {
          ...this.accounts
        };
      }
      this.plan.annualPricing = this.isAnnual;
      if(this.isAnnual) {
        this.plan.planId = ACTIVE_ANNUAL_PLANS.includes(this.planId) ? this.planId : PLANS.PLAN_ANNUAL;
      } else {
        this.plan.planId = ACTIVE_PLANS.includes(this.planId) ? this.planId : PLANS.PLAN_BASIC
      }

      if (this.connectors.length > 0) {
        for (const connector of this.connectors) {
          // this.plan.connectors.push(connector.addonId);
          if(this.planStatus === STATUSES.STATUS_ACTIVE && !connector.trialEnd) {
            this.plan.selectedConnectors.push(connector.addonId)
          }
        }
      }

      this.plan.isCustom = true;
      if(this.planStatus !== STATUSES.STATUS_ACTIVE) {
        this.plan.paidConnectors = []
        this.plan.paidUsers = 0
        this.plan.paidAccounts = 0
      } else {
        for(const connector of this.connectors) {
          if(!connector.trialEnd && !BETA_CONNECTORS.includes(connector.identifier)) {
            this.plan.paidConnectors.push(connector.addonId)
          }
        }
        this.plan.paidUsers = this.plan.users.limit
        this.plan.paidAccounts = this.plan.accounts.limit
      }
      setTimeout(() => this.estimateCost(), 0)
    },
    mounted() {
      this.$nextTick(() => {
        // used to skip event triggered during mount.
        this.debounceEstimate()
        this.hasLoaded = true;
      })
    },
    methods: {
      showLimitedOfferMessage() {
        return this.isBusinessUnitMegalytic()
          && this.applicableDiscounts
          && this.applicableDiscounts.monthly
          && this.applicableDiscounts.annual;
      },
      beforeUnloadEventHandler(event) {
        if (this.isCheckoutClosingAbruptly()) {
          BillingAPI.updateSubscriptionWebhookStatus('success');
          event.preventDefault();
          event.returnValue = '';
          return event.returnValue;
        }
      },
      closeForm() {
        this.$parent.close();
      },
      getHelpText(entity) {
        if(entity.endsWith('accounts')) {
          return `$${this.skinPlanOptions.accounts.price} per account after ${this.skinPlanOptions.accounts.chargeAt}`
        }
        return `$${this.skinPlanOptions.users.price} per user after ${this.skinPlanOptions.users.chargeAt}`
      },
      getTeamName() {
        if(this.data && this.data.team && this.data.team.name) {
          return this.data.team.name
        }
        return ""
      },
      onLegacyPlans() {
        return !ACTIVE_PLANS.includes(this.planId)
      },
      supportEmail() {
        return `mailto:${this.getFrontEndSkinSupportEmail()}`;
      },
      isCheckoutClosingAbruptly() {
        return this.chargeBeeSteps.length && !this.chargeBeeSteps.includes('thankyou_screen');
      },
      async customisePlan() {
        this.$gtm.trackEvent({
          category: GA_CATEGORIES.BILLING,
          action: GA_CATEGORIES.CLICK,
          label: GA_EVENTS.CHECKOUT,
          event: GA_EVENTS.CHECKOUT,
        })
        const estimateObject = {
          addons: this.formatSelectedAddons(),
          isAnnual: this.plan.annualPricing,
          planId: this.plan.planId,
        };

        const hostedPage = BillingAPI.customise(estimateObject).then((res) => res.data);
        const self = this;

        const chargebeeInstance = await getChargebeeInstance();
        chargebeeInstance.openCheckout({
          hostedPage() {
            return new Promise(resolve => resolve(hostedPage))
          },
          error() {
            self.$buefy.toast.open({
              message: 'Sorry, there was an error processing your payment. Please contact support',
              type: 'is-danger',
            });
            self.$emit('webhookStatus', 'success');
          },
          success() {
            Notification.open({
              duration: 10000, // 10 seconds
              message: 'Updating your payment takes sometime. Please refresh this page in few minutes.',
              type: 'is-success',
            })
            self.$emit('edited');
            self.$parent.close();
            if (self.planStatus !== STATUSES.STATUS_ACTIVE) {
              self.$router.push({ name: 'Feedback' });
            }
          },
          loaded() {
            self.chargeBeeSteps = [];
          },
          step(step) {
            self.chargeBeeSteps.push(step);
            if (step === 'review_screen') {
              // setting webhookStatus to pending just before making payment
              // and we should wait for webhookStatus in the backend
              self.$emit('webhookStatus', 'pending');
            }
          },
          close() {
            if (self.isCheckoutClosingAbruptly()) {
              // If the screen is closed without thankyou_screen in the chargeBeeSteps, then, it was closed abruptly in the middle
              // and hence we are setting webhookStatus to success since the subscription is not changed
              self.$emit('webhookStatus', 'success');
            }
            // we are already updating webhook status to success here and hence
            // resetting chargeBeeSteps to prevent triggering beforeunload event when closing the window after closing the checkout popup
            self.chargeBeeSteps = [];
          }
        });
      },
      connectorsChanged(connectors) {
        this.plan.selectedConnectors = connectors;
        this.debounceEstimate();
      },
      numberChanged(label, amount) {
        if (label === 'Users') {
          if(this.hasLoaded) {
            this.$gtm.trackEvent({
              category: GA_CATEGORIES.BILLING,
              action: GA_CATEGORIES.CLICK,
              label: GA_EVENTS.USERS_CHANGED,
              event: GA_EVENTS.USERS_CHANGED,
              value: amount
            })
          }
          this.usersChanged(amount);
          return;
        }
        if(this.hasLoaded) {
          this.$gtm.trackEvent({
            category: GA_CATEGORIES.BILLING,
            action: GA_CATEGORIES.CLICK,
            label: GA_EVENTS.ACCOUNTS_CHANGED,
            event: GA_EVENTS.ACCOUNTS_CHANGED,
            value: amount
          })
        }
        this.accountsChanged(amount);
      },
      usersChanged(userLimit) {
        this.plan.users.limit = userLimit;
        this.debounceEstimate();
      },
      accountsChanged(accountLimit) {
        this.plan.accounts.limit = accountLimit;
        this.debounceEstimate();
      },
      isArrayEqual(a, b) {
        return JSON.stringify([...a].sort()) === JSON.stringify([...b].sort())
      },
      hasChanges() {
        if(!this.isArrayEqual(this.plan.selectedConnectors, this.plan.paidConnectors)) {
          return true
        }
        if((this.plan.paidAccounts !== this.plan.accounts.limit) || (this.plan.paidUsers !== this.plan.users.limit)) {
          return true
        }
        if(!this.isAnnual && this.plan.annualPricing) {
          return true
        }
        return false
      },
      validate() {
        if((this.plan.users.limit < this.skinPlanOptions.users.chargeAt) || (this.plan.accounts.limit < this.skinPlanOptions.accounts.chargeAt)) {
          this.checkoutDisabled = true
          this.checkoutTooltip = `Please select a minimum of ${this.skinPlanOptions.accounts.chargeAt} Accounts and ${this.skinPlanOptions.users.chargeAt} Users. Don't worry! This won't increase your invoice amount. These are included in your plan.`;
          return;
        }
        if((Math.floor(this.plan.accounts.limit) !== this.plan.accounts.limit) || (Math.floor(this.plan.users.limit) !== this.plan.users.limit)) {
          this.checkoutDisabled = true
          this.checkoutTooltip = "Please select a valid value for Users and Accounts"
          return;
        }
        if(this.plan.selectedConnectors.length === 0) {
          this.checkoutDisabled = true;
          this.checkoutTooltip = "Select Atleast one connector"
          return
        }
        if(!this.isArrayEqual(this.plan.selectedConnectors, this.plan.paidConnectors) || (this.plan.paidAccounts !== this.plan.accounts.limit) || (this.plan.paidUsers !== this.plan.users.limit)) {
          this.checkoutDisabled = false
          this.checkoutTooltip = ""
          return;
        }
        if(!this.isAnnual && this.plan.annualPricing) {
          this.checkoutDisabled = false
          this.checkoutTooltip = ""
          return;
        }
        this.checkoutDisabled = true
        this.checkoutTooltip = "No Changes Made"
      },
      /**
       * Debounce the estimates otherwise every time the tier is changed it'll also
       * change the account and user amount which also fires an estimate
       */
      debounceEstimate() {
        this.validate()
        setTimeout(() => this.estimateCost(), 0)
      },
      itemCost(identifier, quantity=1) {
        let price = 0
        const addonDetails = this.addonInfo[identifier]
        if(addonDetails && addonDetails.kind === "on_off") {
          price = addonDetails.price / 100
        } else if (identifier === "accounts") {
          price = Math.max(0 , (quantity - this.skinPlanOptions.accounts.chargeAt)) * this.skinPlanOptions.accounts.price
        } else if (identifier === "team-users") {
          price = Math.max(0 , (quantity - this.skinPlanOptions.users.chargeAt)) * this.skinPlanOptions.users.price
        }
        return price;
      },
      estimateCost() {
        let cost = 0;
        this.plan.selectedConnectors.forEach((connector) => {
          cost += this.itemCost(connector, 1)
        });
        cost += this.itemCost("accounts", this.plan.accounts.limit)
        cost += this.itemCost("team-users", this.plan.users.limit)
        cost -= this.bundleDiscount || 0
        if(this.plan.annualPricing) {
          cost *= 12
        }
        this.billingAmount = cost;
      },
      addConnector(connector) {
        if(!this.plan.selectedConnectors.includes(connector)) {
          this.plan.selectedConnectors.push(connector)
          this.debounceEstimate()
        }
      },
      formatSelectedAddons() {
        const addOns = [];
        const limitAddonsPrefixForPlan = LIMIT_ADDONS_PREFIX_FOR_PLAN[this.plan.planId] || '';
        addOns.push({
          id: `${limitAddonsPrefixForPlan}team-users`,
          quantity: this.plan.users.limit
        });
        addOns.push({
          id: `${limitAddonsPrefixForPlan}accounts`,
          quantity: this.plan.accounts.limit
        });

        if (this.plan.selectedConnectors.length) {
          for (const connector of this.plan.selectedConnectors) {
            addOns.push({
              id: connector,
              quantity: 1
            });
          }
        }

        return addOns;
      },
      contactSupport() {
        this.$gtm.trackEvent({
          category: GA_CATEGORIES.BILLING,
          action: GA_CATEGORIES.CLICK,
          label: GA_EVENTS.CONTACT_SUPPORT,
          event: GA_EVENTS.CONTACT_SUPPORT,
        })
      },
      ...mapActions('billing', ['pullAddons']),
      ...mapActions('user', ['getProfile']),
      ...mapGetters('billing', [
        'getAddons',
        'isApplyDiscount',
      ]),
    }
  };
</script>
