<template>
  <section
    v-if="isLoading"
  >
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
    />
  </section>
  <section
    v-else
  >
    <div class="level border-bottom padding-bottom-10">
      <div class="level-item level-left">
        <h1 class="title is-size-5 level-item">
          Plan Usage
        </h1>
        <h2
          v-if="planIsOnTrial()"
          class="subtitle is-primary-color level-item"
        >
          Free Trial - Ends {{ dateEnds() }}
        </h2>
        <h2
          v-if="planIsCancelled()"
          class="subtitle is-warning-color level-item"
        >
          Cancelled - Ended {{ dateEnds() }}
        </h2>
        <h2
          v-if="planIsNonRenewing()"
          class="subtitle is-warning-color level-item"
        >
          Cancelling - Ends {{ dateEnds() }}
        </h2>
      </div>
      <div
        class="level-item level-right buttons"
      >
        <b-button
          v-if="canCancelPlan()"
          class="inverted-button"
          @click="cancelSubscription()"
        >
          Cancel Subscription
        </b-button>
        <b-tooltip
          :label="isWebhookStatusPending() ? 'We are updating your plan details. It might take a few minutes. Please refresh page after sometime' : ''"
          type="is-warning"
          size="is-large"
          multilined
        >
          <b-button
            type="is-primary"
            :disabled="isWebhookStatusPending()"
            @click="customisePlan()"
          >
            <span>Customize Plan</span>
          </b-button>
        </b-tooltip>
      </div>
    </div>
    <div
      class="columns"
    >
      <div class="column is-half">
        <div class="columns">
          <div class="column is-half">
            <DataCallout
              v-bind="accounts"
              title="Used Accounts"
              class="tile"
            />
          </div>

          <div class="column is-half">
            <DataCallout
              v-bind="users"
              title="Users Limit"
              class="tile"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <ConnectorsDisplay />
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ConnectorsDisplay from './ConnectorsDisplay';
import DataCallout from './DataCallout';
import BillingAPI from '../api/modules/Billing';
import CustomisePlanForm from './forms/CustomisePlanForm';
import { STATUSES, GA_EVENTS, GA_CATEGORIES } from '../../config/Constants';
import CancelConfirmationModal from './CancelConfirmationModal';

export default {
  name: 'PlanUsage',
  components: {
    ConnectorsDisplay,
    DataCallout,
  },
  data() {
    return {
      users: {
        used: 0,
        limit: 0,
      },
      accounts: {
        used: 0,
        limit: 0,
      },
      connectors: [],
      planId: '',
      planStatus: 'in_trial',
      trialAddon: '',
      plans: {},
      tier: {},
      isAnnual: false,
      isLoading: true,
      webhookStatus: 'success',
      applicableDiscounts: null
    };
  },
  created() {
    this.updateBillingInfo();
  },
  methods: {
    isWebhookStatusPending() {
      return this.webhookStatus === 'pending';
    },
    planIsOnTrial() {
      return this.planStatus === STATUSES.STATUS_ON_TRIAL;
    },
    planIsCancelled() {
      return this.planStatus === STATUSES.STATUS_CANCELLED;
    },
    planIsNonRenewing() {
      return this.planStatus === STATUSES.STATUS_NON_RENEWING;
    },
    canCancelPlan() {
      return this.planStatus === STATUSES.STATUS_ACTIVE;
    },
    dateEnds() {
      const endDate = this.getEndDate();

      if (endDate) {
        return moment(String(endDate)).format('ll');
      }
    },
    planIsActive() {
      return this.planIsOnTrial() || this.planStatus === STATUSES.STATUS_ACTIVE;
    },
    updateBillingInfo() {
      this.isLoading = true
      this.getBillingInfo(true).then(() => {
        const users = this.getUserLimit();
        this.users.used = users.totalUsers;
        this.users.limit = users.limit;

        this.accounts = this.getAccountLimit();
        this.connectors = this.getConnectors();
        this.planStatus = this.getPlanStatus();
        this.isAnnual = this.isAnnualPlan();
        this.planId = this.getPlanId();
        this.applicableDiscounts = this.getApplicableDiscounts();
        this.webhookStatus = this.getWebhookStatus();
        this.setLoadingState(false);
        this.isLoading = false
      });
    },
    customisePlan() {
      this.$gtm.trackEvent({
        category: GA_CATEGORIES.BILLING,
        action: GA_CATEGORIES.CLICK,
        label: GA_EVENTS.CUSTOMIZE_PLAN,
        event: GA_EVENTS.CUSTOMIZE_PLAN,
      })
      Vue.prototype.addModal({
        parent: this,
        component: CustomisePlanForm,
        hasModalCard: false,
        props: {
          users: this.users,
          accounts: this.accounts,
          connectors: this.connectors,
          planId: this.planId,
          isAnnual: this.isAnnual,
          planStatus: this.planStatus,
          applicableDiscounts: this.applicableDiscounts
        },
        fullScreen: true,
        canCancel: true,
        events: {
          edited: () => {
            this.setLoadingState(true);
            // wait a bit for the chargebee webhook to work its way around
            setTimeout(this.updateBillingInfo, 7000);
          },
          webhookStatus: (webhookStatus) => {
            BillingAPI.updateSubscriptionWebhookStatus(webhookStatus);
          }
        },
      });
    },
    cancelSubscription() {
      this.$gtm.trackEvent({
        category: GA_CATEGORIES.BILLING,
        action: GA_CATEGORIES.CLICK,
        label: GA_EVENTS.CANCEL_SUBSCRIPTION,
        event: GA_EVENTS.CANCEL_SUBSCRIPTION
      });
      this.$buefy.modal.open({
        parent: this,
        component: CancelConfirmationModal,
        hasModalCard: true,
        canCancel: false
      });
    },
    ...mapActions('app', ['setLoadingState']),
    ...mapActions('billing', ['getBillingInfo', 'getPlans']),
    ...mapGetters('billing', [
      'getAccountLimit',
      'getConnectors',
      'getCurrentPlan',
      'getUserLimit',
      'getPlanStatus',
      'getPlanPeriod',
      'getEndDate',
      'getPlanId',
      'isAnnualPlan',
      'getWebhookStatus',
      'getApplicableDiscounts'
    ]),
  },
};
</script>
