<template>
  <section>
    <b-table
      :data="invoices"
      :striped="true"
      :hoverable="true"
      :loading="isLoading"
      :sort-icon="sortIcon"
      default-sort="date"
      default-sort-direction="desc"
      is-fullwidth
    >
      <template slot-scope="props">
        <b-table-column
          field="id"
          label="Invoice Number"
          sortable
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column
          field="date"
          label="Date"
          sortable
        >
          {{ props.row.date | humanDate }}
        </b-table-column>

        <b-table-column
          field="amountPaid"
          label="Amount"
          sortable
        >
          {{ props.row.amountPaid | chargebeeAmount | currency }}
        </b-table-column>

        <b-table-column
          custom-key="buttons"
        >
          <div
            class="buttons is-centered"
          >
            <b-button
              type="is-text"
              icon-right="download"
              icon-pack="fas"
              class="no-underline edit-button"
              @click="downloadInvoice(props.row.id)"
            />
          </div>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                pack="fas"
                icon="frown"
                size="x-large"
              />
            </p>
            <p>No invoices have been generated yet</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BillingAPI from '../api/modules/Billing';

export default {
  name: 'InvoiceList',
  data() {
    return {
      sortIcon: 'arrow-up',
      isLoading: true,
      invoices: [],
    };
  },
  created() {
    this.pullInvoices(true).then(() => {
      this.isLoading = false;
      this.invoices = this.getInvoices();
    });
  },
  methods: {
    downloadInvoice(id) {
      BillingAPI.downloadInvoice(id).then((res) => {
        window.open(res.data.url, '_blank');
      }).finally(() => {
        this.$buefy.toast.open({
          message: 'Invoice opened in new tab',
          type: 'is-success',
        });
      });
    },
    ...mapActions('billing', ['pullInvoices']),
    ...mapGetters('billing', ['getInvoices']),
  },
};
</script>

<style scoped>

</style>
