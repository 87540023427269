const constants = require('../../server/src/config/constants');

export const CONNECTOR_CONFIG = {
  "adroll": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbyI0OFi1Y9V_9gnX8gwLUcRzJT6YuY3KiRv7U-Wm6bxY2iMk6DC6MMePQjVfgpnY9oMRg",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycbykYtIb2q8d4qey-9pD8id_gqUJvAJSvyaCRNZBNq_TaMamnmS2C7-hPDbdSO-kwWG5uQ"
  },
  "facebook-ads": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbz6x8BFv9V6IUBc04PqXHONF0boqb_Sh7OAloPzuOQS0rHsPpP9IYFTV6Ja2hNbhuVv",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycbyGV-GzRgfG6-19W0Otis6DD0dslgxl_6Uec4346fbePWPbrpa1ZzyZJuimw8SZempubQ",
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: "AKfycby8SFdm-eOnn-WrGcBTc57k01wzLBXtZdbG3F7Zkzsd6faUN7wRWvquptOBveqI41VMoA"
  },
  "facebook-insights": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbyx8QCbN7rOCJSu0M6yNsRuHACEYPD9T7OfpavUtlEqjFMA9V9ughZ5kjIinHdC0CD1",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycbx53XeK6BCGI3E5_ZfMG8UxyQEdjVPZDTiIYw8CRBOxPBsKNwQuO2ZPPrFeG_u1K6Sjtg",
  },
  "linkedin-ads": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbygu6CCamKi66uXkIp0Yjvh6u5ruaArU5epBdLJKR4xk66B7rtFLkOrkDdqtlGU9SX2",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycby6lCIG8fDYnUwzdwvYosrZTktG6PDwn2P7OYDMYIFMufGr6RfkebtApE4TF86eNW4PsA",
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: "AKfycbxX7k4S93rrCZRxE6XV_-pQ7367Q_whL2c47QflI6Eu_T4X-8QAxMsZr-bxk7hy0HWD1g"
  },
  "bing-ads": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbyWNuU77qhwWxs_g-r_RIEBj5T1gm_2eOjbWPLHmC8sJam1_WndBbjH9YBGc2uua60coQ",
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: 'AKfycbyp73rTV3lhHXn8KelvrVvDwrOeWNtuPczI26HQSEM8rMdQaSH5R8AGxHI7P5TIaRTNbA',
  },
  "google-analytics": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbziPAL-PwVN5Xmn5XNUWQqkcvdNFTH00Bt5kOfdAQtOPbOugZ5UEA0VgOgT63aNcjJI"
  },
  "google-ads": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbwqqrLH0W66iC0BuvyI16vrxZ5yEDr-uIa3V9LbjT5LGP8w2vLzqxDLiAFWraKa2b69pQ"
  },
  "linkedin-pages": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbxTLbWRHaLrZ2RemflNBF-wxmUT0wiNyfhYGJrGtzbWIajZ-PwNNjLL4XB0yDwjSb7_",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycbwmRq3DwhhYh3hsgzKgtoNjz0QIFl2owzRW0Hpsa6bGnN634wwElTi4wweeCtm0mHzo"
  },
  "hubspot-analytics": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbwB-zQV2WlMLKKIGQXYzn2rY_GyGI5_gIQVNkTywAvYgF6IMLj6eoZPQqvplPfmQfI",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycbzM0D5XcaSG9Q3rj2lQNVEqfK65BAVxiwWnVc6orhnFNThPPo1zso8-iHfXp-3SQSPg"
  },
  "snapchat-ads": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbwyW4BJdsIL97A2qhEGK8DlzFeEs-01MwVr4xKwe7zHldGE3p6QfZx0YbaEH2sDMyxowA",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycbx9ZMNZGDt1zZQAMnlcoNGKQCiMWgb6KpMh9DtY9qnU0xDiFCkfarp9gEJankcc_REg"
  },
  "mailchimp": {
    [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: "AKfycbyTK37KhOEotv6UsNssBd0nt3A6I-RHFdMgi-Zp1zNaNbjoPY0U6t3YakSxM71LpMSl",
    [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: "AKfycbzWOoRtnMEp8-FPv9q7UMMQdfvvn77K7-0pZp8d1tHnOWEnh-2UY88Vzjn1HZylseCJ"
  },
  'pinterest-ads': {
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: 'AKfycbxpNTlqllgJ_guaX5UL_c59GBb2xxAg4IwT-QwbANIC0KLG-ZUrU38ihyihoTN_y79j8A',
  },
  'instagram-analytics': {
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: 'AKfycbxWH-soYT03AWj5y5F4Qc7zzYx2cxX9PjGhSyAawG5KhM1oJHQCDQWH0adKF05MrydB',
  },
  'google-analytics-4': {
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: 'AKfycby77jqRzCNli_JkfxJw6p2Mbkxu8lgeCi6LVt5QY3Bd_E65hOM_ggoHsCvK2JcRRHCt',
  },
  'twitter-ads': {
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: 'AKfycbyRo8gT20djYiupZ2-MO8tC6unzTOXPbyts4u_0-dnLE2zdvgbCge5DBlBOrNK0jkxT',
  },
  'spotify-ads': {
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: 'AKfycbx4HStmVooYC9T8hDOfUUPVKUqLUoYSBcQsUdGDXR5lsxFEg-MOim_2Jmxy-NgDvyrGCw',
  },
  'adobe-analytics': {
    [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: 'AKfycbyPEFtJsUR2yFtMncI-kPe5cT81HPOPiQLx-vpa7j8H_YUw40edE0zyJZ_mPTkp3MNd',
  }
};
