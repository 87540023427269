<template>
  <section class="cancellation-panels">
    <div class="modal-card">
      <section class="container">
        <div
          class="close-modal"
          @click="$emit('close')"
        />
        <section class="confirmation-panel">
          <b class="panel-title">Are you sure want to cancel?</b>
          <br>
          <br>
          If you're sure you'd like to cancel your subscription,<br>finish below
          <br>
          <br>
          <div class="cancellation-info">
            <ul>
              <li>
                Canceling your subscription will stop fetching data for all your reports
              </li>
              <li>
                Your account information and payment method will be kept
              </li>
            </ul>
          </div>
          <br>
          <br>
          <div class="buttons">
            <b-button
              class="inverted-button rounded-button"
              @click="cancelSubscription"
            >
              Finish Cancellation
            </b-button>
            <b-button
              class="rounded-button"
              type="is-primary"
              @click="$emit('close')"
            >
              <p>Go Back</p>
            </b-button>
          </div>
        </section>
        <section class="panel-partition" />
        <section class="stick-around-panel">
          <div>
            <img
              class="is-circular"
              src="../../public/img/stick-around.png"
            >
            <br>
            <b class="panel-title">Stick Around</b>
            <br>
            Let us know your challenges. Get best offers.
            <br>
            <br>
            <b-button
              class="rounded-button"
              type="is-primary"
              tag="a"
              href="https://calendly.com/sandhya-konjarla/datasolutions"
              target="_blank"
            >
              Talk to our Product Manager
            </b-button>
          </div>
        </section>
      </section>
    </div>
  </section>
</template>

<script>
import BillingAPI from '../api/modules/Billing';
import ChurnFeedbackModal from './ChurnFeedbackModal';

export default {
  name: 'CancelConfirmationModal',
  data() {
    return {}
  },
  methods: {
    cancelSubscription() {
      BillingAPI.cancelSubscription().then((res) => {
        if (res.data.status === 'Success') {
          this.$emit('close');
          this.$buefy.modal.open({
            parent: this,
            component: ChurnFeedbackModal,
            hasModalCard: true,
            canCancel: false
          });
        }
      });
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  height: 330px;
  padding: 40px;
  gap: 30px;
}
.modal-card {
  width: 920px;
  border-radius: 20px !important;
  color: #282C2E;
}
.stick-around-panel{
  text-align: center;
  padding-top: 15px;
}
.panel-title {
  font-size: 1.3rem;
  color: #282C2E;
}
.buttons {
  justify-content: flex-end;
  gap: 10px;
}
ul {
  list-style: initial;
}
.cancellation-info {
  padding-left: 20px;
  width: 480px;
  height: 48px;
}
</style>
