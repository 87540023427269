<template>
  <div
    v-if="hasInvoice"
    class="dataCallout card static margin-bottom-20"
  >
    <p class="is-size-6">
      <b>{{ titleSnippet }}</b>
    </p>
    <p class="is-size-7">
      Payment on {{ date | humanDate }}
    </p>
    <p class="is-primary-color is-size-5 margin-top-10">
      <b v-if="isLatest()">
        {{ amount | chargebeeAmount | currency }}
      </b>
      <b v-else>
        {{ amount | currency }}
      </b>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BillingAPI from '../api/modules/Billing';

export default {
  name: 'PendingPayment',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasInvoice: false,
      titleSnippet: this.isLatest() ? 'Latest Billed Amount' : 'Next Billing Amount',
      date: '',
      amount: 0,
    };
  },
  mounted() {
    if (this.isLatest()) {
      this.pullInvoices().then(() => {
        const mostRecentInvoice = this.getMostRecentInvoice();

        if (typeof mostRecentInvoice.amountPaid !== 'undefined') {
          this.date = mostRecentInvoice.date;
          this.amount = mostRecentInvoice.amountPaid;
          this.hasInvoice = true;
        }
      });
    } else {
      BillingAPI.estimateNextBillingCycle().then((res) => {
        this.date = res.data.next_billing_at;
        this.amount = res.data.next_billing_amount;
        this.hasInvoice = this.amount > 0;
      });
    }
  },
  methods: {
    isLatest() {
      return this.type === 'latest';
    },
    ...mapActions('billing', ['getNextPayment', 'pullInvoices']),
    ...mapGetters('billing', ['getMostRecentInvoice', 'getPayments']),
  }
};
</script>

<style scoped>

</style>
