<template>
  <section>
    <div class="panel">
      <p class="panel-heading is-size-6 no-borders">
        <b>Connectors</b>
      </p>

      <div
        class="panel-block no-borders"
      >
        <div
          v-if="!hasConnectors()"
          class="message"
        >
          No Accounts Linked. Go to the Data Sources page to link an Account.
        </div>
        <ul
          v-else
          class="is-width-100"
        >
          <li
            v-for="addOn in allowedConnectors"
            :key="addOn.addonId"
            class="border-bottom padding-top-2 padding-bottom-2"
          >
            <div class="columns is-vcentered">
              <div class="column is-narrow is-1">
                <img
                  :src="getServiceLogo(addOn.internal)"
                  class="connector-icon margin-left-half-rem margin-right-half-rem level-center"
                >
              </div>
              <div class="column is-10">
                {{ addOn.name }}
                <b
                  v-if="addOn.isBeta"
                  class="is-green"
                >
                  FREE, IN BETA
                </b>
                <b
                  v-if="addOn.isFreemium"
                  class="is-green"
                >
                  FREE CONNECTOR
                </b>
                <b
                  v-else-if="hasDisplayText(addOn)"
                  class="is-red"
                >
                  {{ getDisplayText(addOn) }}
                </b>
              </div>
              <div
                v-if="canLinkToGoogle(addOn)"
                class="column is-narrow is-1"
              >
                <a
                  :href="getConnectorURL(addOn)"
                  title="Add to Google Data Studio"
                  class="is-pulled-right padding-bottom-2"
                  target="_blank"
                >
                  <b-icon
                    pack="fas"
                    icon="chart-line"
                    size="is-medium"
                    class="is-primary-color"
                  />
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {STATUSES, DATA_STUDIO_URL, ACTIVE_PLANS} from '../../config/Constants';
import { CONNECTOR_CONFIG } from '../../config/ConnectorConfig';

export default {
  name: 'ConnectorsDisplay',
  data() {
    return {
      studioURLs: {},
    };
  },
  computed: {
    allowedConnectors() {
      if (this.isTieredPricingModel()) {
        let allowedConnectors = [];

        let planAddons = [];
        const identifier = 'identifier';
        if (
          this.data.plan.subscriptionDetails.status === STATUSES.STATUS_ON_TRIAL ||
          ACTIVE_PLANS.includes(this.data.plan.subscriptionDetails.planId)
        ) {
          planAddons = this.data.plan.connectors;
        } else {
          const plan = this.getCurrentPlan();
          planAddons = plan ? plan.addons : [];
        }
        for (const planAddon of planAddons) {
          const fullAddon = { ...this.getConnectorByField('internal', planAddon[identifier], true) };
          for (const field of ['trialEnd', 'status']) {
            if (Object.prototype.hasOwnProperty.call(planAddon, field)) {
              fullAddon[field] = planAddon[field];
            }
          }

          allowedConnectors.push(fullAddon);
        }
        allowedConnectors = allowedConnectors.filter(Boolean);
        return allowedConnectors.sort((a, b) => a.name.localeCompare(b.name, 'en', {'sensitivity': 'base'}));
      }

      const allowedConnectors = [];
      const connectors = this.getConnectors();
      if (connectors) {
        for (const addon of connectors) {
          const fullAddon = { ...this.getConnectorByField('internal', addon.identifier, true) };
          if (fullAddon) {
            fullAddon.isFreemium = addon.isFreemium;
            for (const field of ['trialEnd', 'status']) {
              if (Object.prototype.hasOwnProperty.call(addon, field)) {
                fullAddon[field] = addon[field];
              }
            }

            allowedConnectors.push(fullAddon);
          }
        }
      }
      return allowedConnectors;
    },
    ...mapState('connectors', ['data']),
    ...mapState('billing', ['data']),
  },
  mounted() {
    this.getBillingInfo().then(() => {
      this.getPlans().then(() => {
      });
    });
  },
  methods: {
    hasConnectors() {
      return this.allowedConnectors.length > 0
    },
    canLinkToGoogle(addon) {
      return this.hasConnectorURL(addon) && !this.trialIsExpired(addon);
    },
    hasConnectorURL(addon) {
      const businessUnit = this.getFrontEndSkinBusinessUnit();
      const identifier = 'internal';
      if (typeof this.studioURLs[addon[identifier]] === 'undefined') {
        if (Object.prototype.hasOwnProperty.call(CONNECTOR_CONFIG, addon[identifier])) {
          const config = CONNECTOR_CONFIG[addon[identifier]];
          if (Object.prototype.hasOwnProperty.call(config, businessUnit)) {
            this.studioURLs[addon[identifier]] = `${DATA_STUDIO_URL}${config[businessUnit]}`;
          }
        }
      }

      return Object.prototype.hasOwnProperty.call(this.studioURLs, addon.internal);
    },
    getConnectorURL(addon) {
      return this.studioURLs[addon.internal];
    },
    hasTrialEndDate(addOn) {
      return Object.prototype.hasOwnProperty.call(addOn, 'trialEnd');
    },
    trialIsExpired(addOn) {
      return Object.prototype.hasOwnProperty.call(addOn, 'status') &&
        addOn.status === STATUSES.STATUS_TRIAL_EXPIRED;
    },
    getDisplayText(addOn) {
      if (addOn.status === 'was_paid')  {
        return 'Buy Again';
      }
      if (this.trialIsExpired(addOn)) {
        return 'Trial Expired';
      }
      if (this.hasTrialEndDate(addOn)) {
        return `Trial ends - ${this.$options.filters.humanDate(addOn.trialEnd)}`;
      }
      return '';
    },
    hasDisplayText(addOn) {
      return !!this.getDisplayText(addOn);
    },
    ...mapActions('billing', [
      'getBillingInfo',
      'getPlans',
    ]),
    ...mapGetters('billing', [
      'getConnectors',
      'getCurrentPlan',
      'isTieredPricingModel',
    ]),
  },
};
</script>
